.group-event-message-card div .name-section .group-event-message-actions-container {
    display: none;
}
.group-event-message-card:hover div .name-section .group-event-message-actions-container {
    display: flex;
} 
.group-event-message-card:hover {
    background-color: #F4F4F4;
}

.group-event-message-card div .name-section .name-date .message-date {
    display: none;
}
.group-event-message-card:hover div .name-section .name-date .message-date {
    display: flex;
}
.group-event-message-action-container { 
    padding: 4px;
}
.group-event-message-action-container:hover {
    background-color: #F4F4F4;
}