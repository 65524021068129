.filter-button {
    border: none;
    background-color: white;
    border-radius: 30px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.filter-button:hover {
    color: black;
}

.filter-button-active {
    border: none;
    background-color: var(--palegray);
    /* background-color: red; */
    border-radius: 30px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    color: var(--darkprimarytext);
}

.filter-button-active:hover {
    color: var(--darkprimarytext);
}

p {
    font-size: 12px;
}