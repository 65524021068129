.social-login-button {
    border-style: solid;
    border-width: 1px;
    border-color: var(--lightgray);
    border-radius: 8px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: white;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}