.attendeeTab-list {
    padding: 0px 16px 0px 16px;
    border-bottom-style: none;
    /* background-color: palegoldenrod; */
}

.attendeeTab-list .react-tabs__tab {
    border: 2px solid #ECECEC;
    border-radius: 8px;
    margin-right: 12px;
    padding-top: 16px;
    padding-right: 16px;
    padding-left: 16px;
    width: 110px;
}

.attendeeTab-list .react-tabs__tab--selected {
    border: 1.75px solid var(--brandblue);
}

.attendeeTab-list .react-tabs__tab--selected p {
    color: var(--brandblue);
}

.attendeeTab-panel {
        padding: 0px 16px 0px 16px;
}
