.venu-card {
    padding: 0;
    background-color: white;
    border: none;
    margin: 0;
}

.venu-card img {
    border-radius: 12px;
    box-shadow: 0px 2px 4px #0000000C;
    overflow: hidden;
}

.venu-card:hover {
    background-color: transparent;
}

.address {
    text-align: left;
    color: var(--darksecondarytext);
}