.react-datepicker-wrapper {
    flex: 1;
    display: flex;
}

input {
        border-style: solid;
        border-color: #F4F4F4;
        border-width: 1px;
        width: 100%;
        padding-top: 8px;
        padding-bottom: 8px;

}

textarea {
   font-family:"Poppins", monospace;  
   font-size: 14px; 
}

.react-datepicker {
    font-family: 'Poppins';
    padding: 20px;
}

.react-datepicker__header {
    background-color: #FFFFFF;
}

.react-datepicker__day-names {
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 10px;
}
.react-datepicker__day-name {
    padding-left: 5px;
    padding-right: 5px;
}

.react-datepicker__day {
    padding: 5px;
}

.react-datepicker__day--selected {
    border-radius: 2em;
    background-color: #3DA2FF;
}

.react-datepicker__day:hover {
    border-radius: 2em;
    background-color: #e7edff;
}