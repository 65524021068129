.show-more-less-clickable {
    color: var(--brandblue);
    border-radius: 4px;
    padding: 2px;
}

.show-more-less-clickable:hover {
    font-weight: 500;
    border-radius: 4px;    
    cursor: pointer;
}
