.other-message-card:hover {
    background-color: #F4F4F4;
}

.other-message-card .message-date {
    display: none;
}

.other-message-card:hover .message-date {
    display: flex;
}