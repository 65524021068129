.picklers-timepicker .react-time-picker {
    flex: 1;
}

.picklers-timepicker .react-time-picker__wrapper {
    border-style: solid;
    border-width: 1px;
    border-color: #f4f4f4;
    border-radius: 8px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 8px; 
    padding-bottom: 8px;
    color: #18042C;
    font-size: 14px;
    font-family: "Poppins";
}

.react-time-picker__inputGroup {
    display: flex;
}

.react-time-picker__inputGroup__leadingZero {
    padding-top: 1px;
}

.picklers-timepicker .react-time-picker__inputGroup__amPm {
    font-family: "Poppins";
    font-size: 14px;
    flex: 1;
    outline: none;
}