.member-button {
    /* border: none; */
    border-style: "solid";
    border-width: 1px;
    border-color: #BCBCBE;
    border-radius: 30px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
}

p {
    font-size: 12px;
}