.modal {
    width: 500px;
    height: 500px;
    background: white;
    border: 1px solid #ccc;
    transition: 1.1s ease-out;
    box-shadow: -2rem 2rem 2rem rgba(black, 0.2);
}


.map {
    height: 200px;
    width: 200px;
}

.map-h2 {
    text-transform: uppercase;
    font-size: 1rem;
    padding: 20px;
    padding-left: 10px;
    text-align: center;
}

.google-map {
    width: 200px;
    height: 200px;
}

.pin {
    display: flex;
    align-items: center;
    width: 180px;
    color: var(--main-blue);
}

.pin-icon {
    font-size: 4rem;
}

.pin-text {
    font-size: 1.3em;
}

.pac-container {
    z-index: 3000;
}