.react-tabs__tab-list {
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;
    border-bottom: 1px solid var(--lightgray);
    margin-bottom: 0;
}

.react-tabs__tab {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--darkprimarytext);
    padding: 0px 0px 16px 0px;
    margin-right: 48px;
}

.react-tabs__tab:hover {
    /* font-weight: 500; */
}

.react-tabs__tab--selected {
    color: var(--brandblue);
    border: 1px solid transparent;
    border-radius: 2px 2px 0 0;
    border-bottom: 4px solid var(--brandblue);
    font-family: 'Poppins';
    line-height: 24px;
}

.react-tabs__tab:focus:after {
    content: none
}

.skeleton-tab-selected {
    border-bottom: none;
}