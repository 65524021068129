.primary-button {
    border: none;
    background-color: #3DA2FF;
    border-radius: 30px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
}

p {
    font-size: 12px;
}