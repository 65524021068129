.dot-separator {
    font-size: 24px;
    padding: 0px 8px 0px 8px;
    line-height: 0px;
}

.card-button {
    padding: 0;
    border-radius: 8px;
    background-color: white;
    border: 1px solid var(--lightgray);
    margin-bottom: 24px;
    box-shadow: 0px 2px 4px #0000000C;
    text-align: start;
}

.group-user-status-label {
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    padding: 4px 8px 4px 8px;
    flex-direction: row;
    display: flex;
    background-color: var(--lightgray);
    color: var(--darksecondarytext);
}
