.my-message-card:hover {
    background-color: #F4F4F4;
}

.my-message-card .message-date {
    display: none;
}

.my-message-card:hover .message-date {
    display: flex;
}

.my-message-card .name-date .my-message-actions-container {
    display: none;
}

.my-message-card:hover .name-date .my-message-actions-container {
    display: flex;
}

.my-message-actions-container {
    padding: 4px;
}

.my-message-actions-container:hover {
    background-color: #F4F4F4;
}