.event-card-button {
    background-color: white;
    padding: 16px 0px 16px 0px;
    border: none;
    width: 100%;
}

.event-card-button:hover {
    background-color: white;
}

        