.nav-button {
    border: none;
    background-color: white;
    align-items: center;
    justify-content: center;
    color: var(--darksecondarytext);
    border-radius: 8px;
    padding: 6px;
}

.nav-button:hover {
    color: black;
}