@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap');
/* body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

p {
  font-family: 'Poppins';
  font-size: 14px;
  margin: 0px;
}

a {
  font-family: 'Poppins';
  font-size: 14px;
  margin: 0px;
}

a:hover {
  cursor: pointer;
}

button:hover {
  cursor: pointer;
  background-color: var(--palegray);
}

img:hover {
  cursor: pointer;
}

h1 {
  font-family: 'Inter';
  font-size: 24px;
  font-weight: 600;
  margin: 0px;
}

h2 {
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 600;
  margin: 0px;
}

input {
  font-family: "Poppins";
  font-size: 14px;
  border-radius: 8px;
  padding-left: 8px;
  padding-right: 8px;
  outline: none;
  border-color: var(--lightgray);
}

:root {
  --lightgray: #ececec;
  --palegray: #f4f4f4;
  --mediumgray: #bcbcbe;
  --darkprimarytext: #18042c;
  --darksecondarytext: #78787a;
  --brandblue: #3da2ff;
  --paleblue: #F2F6FF;
  --palegreen: #e7f4e5;
  --darkgreen: #17773d;
  --paleyellow: #fff4cf;
  --darkyellow: #cf8d0d;
  --palered: #ffe7e7;
  --darkred: #a40303;
  --palepurple: #F6F0FE;
  --darkpurple: #490F93;
}

body {
  margin: 0 !important;
  padding: 0 !important;
}

body>#root>div {
  height: 100vh;
}

.react-confirm-alert-overlay {
  z-index: 3000;
}
.react-confirm-alert div {
  background-color: white
}