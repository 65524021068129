.rsvp-label {
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    padding: 4px 8px 4px 8px;
    flex-direction: row;
    display: flex;
    white-space: nowrap;
}

.rsvp-label-text {
    font-size: 12px;
    padding: 0;
    margin-left: 4px;
}

.rsvp-label-green {
    color: var(--darkgreen);
    background-color: var(--palegreen);
}

.rsvp-label-yellow {
    color: var(--darkyellow);
    background-color: var(--paleyellow);
}

.rsvp-label-red {
    color: var(--darkred);
    background-color: var(--palered);
}

.rsvp-label-purple {
    color: var(--darkpurple);
    background-color: var(--palepurple);
}

.new-activity-label-blue {
    color: var(--brandblue);
    background-color: var(--paleblue);
    text-align: center;
}